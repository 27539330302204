<template>
  <div class="flex h-full grow flex-col">
    <BookingStepHeader class="mb-4 md:mb-0" />

    <main v-if="loading" class="grow px-4 py-12">
      <LoadingSpinner />
      <Guideline
        :title="loadingMessage.title"
        :text="loadingMessage.text"
        class="my-4"
      />
    </main>

    <main v-show="!loading" class="grow">
      <client-only>
        <slot />
      </client-only>
    </main>

    <G7Footer />
  </div>
</template>

<script setup lang="ts">
import type { Ref } from "vue";
import type { BookingLayout } from "@/types/layout";
import type { Order } from "~~/types/order";

const { data } = await useGo7seasApi<BookingLayout>(
  apiUrl("/catalog/homepages/booking")
);

onMounted(() => {
  updateBookingLayout(data.value);
});

const loading: Ref<boolean> = useBookingLoading(true);
const booking: Ref<Order> = useBooking();

// check if there is a booking in process and load it if so
if (useAuth().valid.value) {
  const currentStep = useCurrentStep(useRoute().path);
  if (currentStep !== null) {
    try {
      const resetData = await useBookingApi<Order>(
        apiUrl(`/orders/steps/${currentStep}`),
        {
          method: "PATCH",
        },
        true
      );

      booking.value = resetData as Order;
    } catch (error) {
      navigateTo("/");
    }
  }
}

const loadingMessage = computed(() => booking.value.loading_message);

onUnmounted(() => {
  setBookingFormId(null);
});

if (!process.server) {
  window.onpopstate = function () {
    location.reload();
  };
}
</script>
