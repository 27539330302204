<template>
  <div
    class="flex h-full px-2 text-sm"
    :class="active ? 'bg-teal pb-1' : 'bg-white'"
  >
    <div
      v-if="step.checked"
      class="flex shrink items-center pr-3 text-g7-blue lg:py-1"
    >
      <component
        :is="component"
        :data="step"
        class="whitespace-nowrap text-xs leading-3 md:text-sm"
      />
    </div>
    <div
      v-else
      class="flex flex-col items-center pr-3 pt-2 text-xs leading-4 lg:pt-6 lg:text-sm"
      :class="active ? 'text-white' : 'text-silver'"
    >
      <span v-html="label[0]" />
      <span v-html="label[1]" />
      <span v-html="label[2]" />
    </div>
    <div
      class="mt-2 flex size-4 items-center justify-center rounded-full border border-teal bg-white shadow-inner-lg lg:mt-3 lg:size-5"
    >
      <IconsCheck v-if="step.checked" class="size-2 fill-teal lg:size-3" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Ref } from "vue";
import type { OrderStep } from "~~/types/order";
import { OrderStepEnum } from "~~/types/order";

const label: Ref<Array<string>> = ref([]);
const component = shallowRef(null);
const props = defineProps<{
  step: OrderStep;
  active: boolean;
}>();

const mapping = {
  [OrderStepEnum.CRUISE_INFO]: resolveComponent("BookingStepsCruiseInfo"),
  [OrderStepEnum.OCCUPANCY]: resolveComponent("BookingStepsOccupancy"),
  [OrderStepEnum.CATEGORY]: resolveComponent("BookingStepsCategory"),
  [OrderStepEnum.CABIN]: resolveComponent("BookingStepsCabin"),
  [OrderStepEnum.ADDITIONS]: resolveComponent("BookingStepsTransfers"),
  [OrderStepEnum.TRAVELERS]: resolveComponent("BookingStepsTravelers"),
  [OrderStepEnum.PERSONAL_DATA]: resolveComponent("BookingStepsPayment"),
};

component.value = mapping[props.step.order_step];

onMounted(() => {
  label.value = props.step.label.split(" ");
});
</script>
