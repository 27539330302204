<template>
  <div
    class="flex cursor-pointer flex-col items-center"
    @click="useResetStep(data)"
  >
    <span v-html="label[0]" />
    <span v-html="label[1]" />
    <span v-html="label[2]" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
});

const label: Ref<Array<string>> = ref([]);

onMounted(() => {
  label.value = props.data.label.split(" ");
});
</script>
